@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: local('Inter'), url(./fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: local('Inter'), url(./fonts/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: local('Inter'), url(./fonts/Inter-Black.ttf) format('truetype');
}

/* @import 'react-redux-toastr/src/styles/index'; */

body, html, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E5E5E5;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.container {
  display: flex;
  padding: 16px;
}

.tables-wrapper {
  width: calc(100% - 600px);
}

.select-wrapper {
  display: flex;
  border-bottom: 1px solid #777;
  padding: 16px 0;
  justify-content: space-between;
}

.labs-wrapper {
  margin-top: 16px;
}

.labs-list {
  padding: 0;
}

.lab {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eee;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 4px;
}